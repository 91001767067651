import React, { useState, useCallback } from "react";
import FileUpload from "components/FileUpload";
import { Form, SubmitButton, ErrorMessage } from "components/Forms";
import "./style.css";
import notify from "utils/toastMsg";
import postMaintenanceFrfData from "services/frfDatabase/postMaintenanceFrfData";
import frfDataSchema from "./schemas/frfDataSchema";

const INITIAL_FORM_VALUES = {
  cuttingToolFRFxxUpload: "",
  cuttingToolFRFyyUpload: "",
};

function UploadData({
  fetchAllMaintenanceFrfDataByMachineId,
  setActiveComponent,
  machineId,
}) {
  const [loading, setLoading] = useState(false);

  const handleFrfDataSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const response = await postMaintenanceFrfData({
          ...values,
          machineId,
        });
        fetchAllMaintenanceFrfDataByMachineId();
        notify("Frf data has been received successfully. ✅ ");
        return response;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        notify("Something went wrong. ❌ ");
        throw err;
      } finally {
        localStorage.removeItem("originalNameOf-cuttingToolFRFxxUpload");
        localStorage.removeItem("originalNameOf-cuttingToolFRFyyUpload");
        setLoading(false);
        setActiveComponent(null);
      }
    },
    [fetchAllMaintenanceFrfDataByMachineId, machineId, setActiveComponent]
  );

  const handleModalClick = useCallback(() => {
    localStorage.removeItem("originalNameOf-cuttingToolFRFxxUpload");
    localStorage.removeItem("originalNameOf-cuttingToolFRFyyUpload");
    setActiveComponent(null);
  }, [setActiveComponent]);

  return (
    <div
      className="d-flex justify-content-center align-items-center modal-container-upload-data "
      onClick={handleModalClick}
    >
      <div
        className="main-container-pop-upload-data "
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="pop-up-content-upload-data">
          <h1 className="text-center">Upload New FRF Data</h1>
          <div className="mt-5 mr-5">
            <Form
              initialValues={INITIAL_FORM_VALUES}
              validationSchema={frfDataSchema}
              onSubmit={handleFrfDataSubmit}
            >
              <div className="d-flex row">
                <div className="form-group ml-5 d-flex flex-column">
                  <label>Upload the FRF in the xx direction (.frf/.txt)</label>
                  <FileUpload
                    name="cuttingToolFRFxxUpload"
                    className="form-control"
                  />
                  <ErrorMessage name="cuttingToolFRFxxUpload" />
                </div>
                <div className="form-group ml-5 d-flex flex-column">
                  <label>Upload the FRF in the yy direction (.frf/.txt)</label>
                  <FileUpload
                    name="cuttingToolFRFyyUpload"
                    className="form-control"
                  />
                  <ErrorMessage name="cuttingToolFRFyyUpload" />
                </div>
              </div>
              <div className="d-flex justify-content-around">
                <button
                  className="btn btn-warning waves-effect m-3"
                  onClick={handleModalClick}
                >
                  Cancel
                </button>
                <SubmitButton
                  type="submit"
                  className="btn btn-success waves-effect m-3"
                  disabled={loading}
                >
                  Upload
                </SubmitButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadData;
