import { React } from "react";
import { Input, ErrorMessage, SubmitButton, Select } from "components/Forms";
import "./style.css";
import getMachineToolTypes from "services/getMachineToolTypes";
import useAPI from "effects/useAPI";
import postMachine from "services/postMachine";
import { Form } from "components/Forms";
import notify from "utils/toastMsg";
import handleMachineToolSubmitSchema from "./schemas/handleMachineToolSubmitSchema";

function AddNewMachine({ handleClose, fetchAllMachines }) {
  const [
    machineToolTypesLoading,
    machineToolTypesError,
    machineToolTypesResponse,
  ] = useAPI(() => getMachineToolTypes());

  const handleMachineToolSubmit = async (values) => {
    try {
      await postMachine({
        ...values,
      });
      notify("Machine Tool created successfully. ✅");
      fetchAllMachines();
      handleClose();
    } catch (err) {
      notify("An error occurred while creating the machine tool. ❌");
      throw err;
    }
  };

  const machineToolTypesOptions =
    machineToolTypesResponse?.machineToolTypes?.map((type) => ({
      key: type.machineToolTypeId,
      value: type.machineToolTypeId,
      label: type.machineToolTypeName,
    })) || [];

  return (
    <div
      className="d-flex justify-content-center align-items-center modal-container-add-new-machine "
      onClick={() => handleClose()}
    >
      <div
        className="main-container-pop-add-new-machine "
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="pop-up-content-add-new-machine">
          <div className="d-flex justify-content-end align-items-end">
            <i
              onClick={() => handleClose()}
              type="button"
              className="mdi mdi-close fs-xl"
            ></i>
          </div>
          <h2 className="d-flex justify-content-center title-add-new-machine">
            Add New Machine
          </h2>
          <div className="d-flex justify-content-center flex-column">
            <Form
              initialValues={{
                machineName: "",
                machineModel: "",
                machineToolTypeId: "1",
                maxSpindleSpeed: "",
                maxPower: "",
                maxTorque: "",
              }}
              validationSchema={handleMachineToolSubmitSchema}
              onSubmit={handleMachineToolSubmit}
            >
              <div className="d-flex flex-row justify-content-center align-items-center input-wrapper-add-new-machine">
                <div className="d-flex flex-column justify-content-center align-items-center input-wrapper-add-new-machine">
                  <h4>Machine Name</h4>
                  <Input
                    name="machineName"
                    type="text"
                    className="form-control"
                    placeholder="Machine Name"
                  />
                  <ErrorMessage name="machineName" />
                  <h4>Machine Model</h4>
                  <Input
                    name="machineModel"
                    type="text"
                    className="form-control"
                    placeholder="Machine Model"
                  />
                  <ErrorMessage name="machineModel" />
                  <h4>Machine Type</h4>
                  {machineToolTypesLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <div>
                      {machineToolTypesError ? (
                        <div>Oops! Something went wrong. Please try again.</div>
                      ) : machineToolTypesResponse.machineToolTypes ? (
                        <>
                          <Select
                            name="machineToolTypeId"
                            type="text"
                            className="form-control form-control-size-add-new-machine"
                            placeholder="Select Machine Tool Type"
                            options={machineToolTypesOptions}
                          />
                          <ErrorMessage name="machineToolTypeId" />
                        </>
                      ) : (
                        <div>No machine tool types found.</div>
                      )}
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center input-wrapper-add-new-machine">
                  <h4>Max Spindle Speed</h4>
                  <Input
                    name="maxSpindleSpeed"
                    type="number"
                    className="form-control"
                    placeholder="Max Spindle Speed"
                  />
                  <ErrorMessage name="maxSpindleSpeed" />
                  <h4>Max Power</h4>
                  <Input
                    name="maxPower"
                    type="number"
                    className="form-control"
                    placeholder="Max Power"
                  />
                  <ErrorMessage name="maxPower" />
                  <h4>Max Torque</h4>
                  <Input
                    name="maxTorque"
                    type="number"
                    className="form-control"
                    placeholder="Max Torque"
                  />
                  <ErrorMessage name="maxTorque" />
                </div>
              </div>
              <div className="d-flex mt-3 justify-content-center">
                <SubmitButton
                  className="create-btn-add-new-machine btn-success waves-effect"
                  type="submit"
                >
                  Create
                </SubmitButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewMachine;
