export const getStatusBadgeClass = (status) => {
  switch (status) {
    case "active":
      return "badge-success";
    case "trialing":
      return "badge-info";
    case "canceled":
      return "badge-danger";
    default:
      return "badge-default";
  }
};
