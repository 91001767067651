import React, { useState, useEffect } from "react";
import Authentication from "services/Authentication";
import "./index.css";

const auth = new Authentication();

const SubscriptionModal = ({
  showSubscriptionModal,
  setShowSubscriptionModal,
}) => {
  const jwtPayload = auth.getAccessTokenPayload();
  // eslint-disable-next-line no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const closeModal = () => {
    setShowSubscriptionModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {showSubscriptionModal && (
        <div className="modal-wrapper">
          <div className="subscription-modal-content">
            <div
              className="d-flex justify-content-between align-items-start"
              style={{
                marginRight: "20px",
              }}
            >
              <h2 style={{ marginLeft: "30px" }}>Pricing</h2>
              <button className="close" type="button" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div
              style={{
                fontSize: "22px",
              }}
            >
              <stripe-pricing-table
                client-reference-id={jwtPayload.userId}
                pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
                publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                customer-email={jwtPayload.email}
              ></stripe-pricing-table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SubscriptionModal;
