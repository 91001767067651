import { React, useEffect, useState } from "react";
import SubscriptionModal from "./components/SubscriptionModal";
import Authentication from "services/Authentication";
import { useSubscription } from "../../context/subscriptionContext";
import getPaymentsById from "services/getPaymentsById";
import useAPI from "effects/useAPI";
import { getStatusBadgeClass } from "utils/getStatusBadgeClass";
import isSubscriptionActive from "utils/isSubscriptionActive";
const auth = new Authentication();

function Subscription(props) {
  const jwtPayload = auth.getAccessTokenPayload();

  const subscription = useSubscription();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const badgeClass = getStatusBadgeClass(subscription.subscriptionStatus);

  const [errorPayments, loadingPayments, paymentsResponse] = useAPI(
    () => getPaymentsById(),
    []
  );
  useEffect(() => {
    if (!isSubscriptionActive(subscription)) {
      setShowSubscriptionModal(true);
    } else setShowSubscriptionModal(false);
  }, [subscription]);

  if (errorPayments) {
    return <p>Error Loading Payments</p>;
  }

  if (loadingPayments) {
    return <p>Loading Payments...</p>;
  }

  const { paymentDetails } = paymentsResponse;

  const handleBuySubscription = () => {
    setShowSubscriptionModal(true);
  };

  const userEmail = jwtPayload.email;
  const encodedEmail = encodeURIComponent(userEmail);
  const customerPortalLink = `${process.env.REACT_APP_STRIPE_PORTAL_BASE_URL}?prefilled_email=${encodedEmail}`;

  return (
    <div
      style={{
        marginTop: "8em",
        marginLeft: props.enlarged && "80px",
      }}
    >
      <div
        className="row cardss"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xl-9">
          <h3 className="mt-5 mb-5">Subscriptions</h3>

          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div>
                    <h4 className="mb-2">Your Subscription</h4>
                  </div>
                  <div className="table-responsive">
                    <h5>
                      {subscription.subscribedProduct}
                      <span
                        className={`badge ${badgeClass}`}
                        style={{ margin: 10 }}
                      >
                        {subscription.subscriptionStatus}
                      </span>
                    </h5>
                  </div>
                  <p>
                    Please follow the button to manage your subscription and
                    payment details
                  </p>
                </div>

                {isSubscriptionActive(subscription) ? (
                  <a href={customerPortalLink} target="_blank" rel="noreferrer">
                    <button className="btn btn-success btn-lg waves-effect waves-light ml-2">
                      Manage Subscription
                    </button>
                  </a>
                ) : (
                  <button
                    className="btn btn-success btn-lg waves-effect waves-light ml-2"
                    onClick={handleBuySubscription}
                  >
                    Buy Subscription
                  </button>
                )}
              </div>
            </div>
          </div>
          {showSubscriptionModal && (
            <SubscriptionModal
              showSubscriptionModal={showSubscriptionModal}
              setShowSubscriptionModal={setShowSubscriptionModal}
            />
          )}
        </div>
      </div>
      <div
        className="row cardss"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xl-9">
          <div className="card" style={{ marginBottom: "100px" }}>
            <div className="card-body">
              <div>
                <div className="d-flex justify-content-between">
                  <h4 className="mt-0 header-title mb-4">Payment History</h4>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Amount</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Description</th>
                      <th scope="col">Invoice No.</th>
                      <th scope="col">Transaction Datetime</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentDetails.map((payment, index) => (
                      <tr key={index}>
                        <td>{payment.amount}</td>
                        <td>{payment.currency}</td>
                        <td>{payment.paymentMethod}</td>
                        <td>{payment.description}</td>
                        <td>{payment.stripeInvoiceNo}</td>
                        <td>{payment.transactionDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
