import http from "utils/http";
import { frfDatabase } from "constants/endpoints";

const postMaintenanceFrfData = ({
  cuttingToolFRFxxUpload,
  cuttingToolFRFyyUpload,
  cuttingToolFRFxyUpload,
  cuttingToolFRFyxUpload,
  machineId,
}) => {
  const POST_USER_ENDPOINT = frfDatabase.postMaintenanceFrfData();
  const { REACT_APP_API_BASE_URL: API_BASE_URL } = process.env;
  return http.post(`${API_BASE_URL}${POST_USER_ENDPOINT}`, {
    data: {
      cuttingToolFRFxxUpload,
      cuttingToolFRFyyUpload,
      cuttingToolFRFxyUpload,
      cuttingToolFRFyxUpload,
      machineId,
    },
  });
};

export default postMaintenanceFrfData;
