import React from "react";
import { Route } from "react-router-dom";

const checkRegexOrString = (checkToPerform, currentPath) => {
  if (checkToPerform instanceof RegExp) {
    return checkToPerform.test(currentPath);
  } else {
    return checkToPerform === currentPath;
  }
};

const RenderRouteIf = ({ children, onRoutes = [], notOnRoutes = [] }) => {
  return (
    <Route
      render={({ location }) => {
        const currentPath = location.pathname.toLowerCase();

        if (
          onRoutes.length > 0 &&
          onRoutes.some((check) => checkRegexOrString(check, currentPath)) &&
          notOnRoutes.length > 0 &&
          !notOnRoutes.some((check) => checkRegexOrString(check, currentPath))
        ) {
          return children;
        }
        if (
          onRoutes.length > 0 &&
          onRoutes.some((check) => checkRegexOrString(check, currentPath))
        ) {
          return children;
        }
        if (
          notOnRoutes.length > 0 &&
          !notOnRoutes.some((check) => checkRegexOrString(check, currentPath))
        ) {
          return children;
        }
        return null;
      }}
    />
  );
};

export default RenderRouteIf;
