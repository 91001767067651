import React, { useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useHistory } from "react-router";
import UploadFrfData from "./pages/UploadFrfData/UploadData";
import ReportAnEvent from "./pages/ReportAnEvent/index";
import SelectModes from "./pages/SelectModes";
import useAPI from "effects/useAPI";
import getMaintenanceFrfDataByMachineId from "services/frfDatabase/getMaintenanceFrfDataByMachineId";
import deleteMaintenanceFrfDataById from "services/frfDatabase/deleteMaintenanceFrfDataById";
import notify from "utils/toastMsg";
import GetFileAsJson from "components/GetFileAsJson";
import getMachineByMachineId from "services/getMachineByMachineId";
import getEventLogsByMachineId from "services/getEventLogsByMachineId";
import DeleteFrfRecordById from "./components/DeleteFrfRecordById";
// import UpdateEventStatusByEventId from "./components/UpdateEventStatusByEventId";
// import putEventLog from "services/frfDatabase/putEventLog";

const calculateMagnitude = (real, imag) => {
  return Math.sqrt(Math.pow(real, 2) + Math.pow(imag, 2));
};

const parseData = (dataStringX, dataStringY) => {
  if (!dataStringX || !dataStringY) {
    return [];
  }
  const linesX = dataStringX.split("\n");
  const linesY = dataStringY.split("\n");
  let dataX = linesX.slice(1).map((line, index) => {
    const [frequencyX, realX, imagX] = line
      .split("\t")
      .slice(0, 3)
      .map((item) => Number(item.replace(",", ".")));
    const magnitudeX = calculateMagnitude(realX, imagX);
    return { frequency: frequencyX, magnitudeX };
  });
  let dataY = linesY.slice(1).map((line, index) => {
    const [frequencyY, realY, imagY] = line
      .split("\t")
      .slice(0, 3)
      .map((item) => Number(item.replace(",", ".")));
    const magnitudeY = calculateMagnitude(realY, imagY);
    return { frequency: frequencyY, magnitudeY };
  });

  let data = dataX.map((item, index) => {
    return {
      frequency: item.frequency,
      magnitudeX: item.magnitudeX,
      magnitudeY: dataY[index].magnitudeY,
    };
  });

  data = data
    .filter((item) => !isNaN(item.magnitudeX) || !isNaN(item.magnitudeY))
    .slice(50);
  return data;
};

const formatDate = (date) => {
  const d = new Date(date);
  return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
    "0" + d.getDate()
  ).slice(-2)}`;
};

const SpindleHealthMonitoring = () => {
  const [activeComponent, setActiveComponent] = useState(null);
  const [frfData, setFrfData] = useState({ frfXxData: null, frfYyData: null });
  const [activeUrl, setActiveUrl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [frfDatumIdToDelete, setFrfDatumIdToDelete] = useState(null);
  const [activeMagnitudes, setActiveMagnitudes] = useState(["magnitudeX"]);
  const [frfTableOffset, setFrfTableOffset] = useState(0);
  const [eventTableOffset, setEventTableOffset] = useState(0);

  const history = useHistory();
  const { machineId } = useParams();

  const [
    machineByMachineIdLoading,
    machineByMachineIdError,
    machineByMachineIdResponse,
  ] = useAPI(() => getMachineByMachineId({ machineId }));

  const [
    eventLogsByMachineIdLoading,
    eventLogsByMachineIdError,
    eventLogsByMachineIdResponse,
    fetchAllLogsByMachineId,
  ] = useAPI(
    () => getEventLogsByMachineId({ machineId, eventTableOffset }),
    [eventTableOffset]
  );

  const [
    maintenanceFrfDataByMachineIdLoading,
    maintenanceFrfDataByMachineIdError,
    maintenanceFrfDataByMachineIdResponse,
    fetchAllMaintenanceFrfDataByMachineId,
  ] = useAPI(
    () => getMaintenanceFrfDataByMachineId({ machineId, frfTableOffset }),
    [frfTableOffset]
  );

  const { machineDetails } = machineByMachineIdResponse;
  const { maintenanceFrfDataByMachineId } =
    maintenanceFrfDataByMachineIdResponse;
  const { eventLogs } = eventLogsByMachineIdResponse;

  const chartButtons = useMemo(
    () => [
      {
        label: "XX",
        className: `btn waves-effect ${
          activeMagnitudes.includes("magnitudeX")
            ? "btn active-button"
            : "btn not-active-button"
        }`,
        onClick: () => {
          setActiveMagnitudes((prev) =>
            prev.includes("magnitudeX")
              ? prev.filter((magnitude) => magnitude !== "magnitudeX")
              : [...prev, "magnitudeX"]
          );
        },
      },
      {
        label: "YY",
        className: `btn waves-effect ${
          activeMagnitudes.includes("magnitudeY")
            ? "btn active-button"
            : "btn not-active-button"
        }`,
        onClick: () => {
          setActiveMagnitudes((prev) =>
            prev.includes("magnitudeY")
              ? prev.filter((magnitude) => magnitude !== "magnitudeY")
              : [...prev, "magnitudeY"]
          );
        },
      },
    ],
    [activeMagnitudes]
  );

  const componentMapping = useMemo(
    () => ({
      UploadFrfData: (
        <UploadFrfData
          fetchAllMaintenanceFrfDataByMachineId={
            fetchAllMaintenanceFrfDataByMachineId
          }
          setActiveComponent={setActiveComponent}
          machineId={machineId}
        />
      ),
      ReportAnEvent: (
        <ReportAnEvent
          setActiveComponent={setActiveComponent}
          fetchAllLogsByMachineId={fetchAllLogsByMachineId}
          machineId={machineId}
        />
      ),
      SelectModes: <SelectModes setActiveComponent={setActiveComponent} />,
    }),
    [fetchAllMaintenanceFrfDataByMachineId, fetchAllLogsByMachineId, machineId]
  );

  const handleFileLoad = useCallback(
    (data) => {
      if (data.frfXxData && data.frfYyData) {
        setFrfData({ frfXxData: data.frfXxData, frfYyData: data.frfYyData });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUrl]
  );

  const handleDeleteFrfDataByFrfDatumId = async (frfDatumId, selectedRow) => {
    try {
      if (frfDatumId.length <= 1) {
        notify("Machine should have at least 1 frf data. ❌");
      } else {
        await deleteMaintenanceFrfDataById({
          frfDatumId,
        });
        if (frfDatumId === selectedRow) {
          setSelectedRow(null);
          setFrfData({ frfXxData: null, frfYyData: null });
        }
        notify("Frf Data Deleted Successfully. ✅ ");
        fetchAllMaintenanceFrfDataByMachineId();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      notify("An error occurred while deleting the data. ❌");
    }
  };

  const renderTableRows = (maintenanceFrfDataByMachineId) => {
    if (
      !maintenanceFrfDataByMachineId ||
      maintenanceFrfDataByMachineId.length === 0
    ) {
      return "";
    }
    return maintenanceFrfDataByMachineId.map((data) => (
      <tr
        key={data.frfDatumId}
        style={{
          cursor: "pointer",
          height: "10px",
          border:
            selectedRow === data.frfDatumId
              ? "3px solid rgb(43, 142, 211)"
              : "",
        }}
        onClick={() => {
          setActiveUrl({ frfXxUrl: data.frfXxUrl, frfYyUrl: data.frfYyUrl });
          setSelectedRow(data.frfDatumId);
        }}
      >
        {activeUrl && (
          <GetFileAsJson
            frfXxUrl={activeUrl.frfXxUrl}
            frfYyUrl={activeUrl.frfYyUrl}
            onFileLoad={handleFileLoad}
          />
        )}
        <td className="text-center">{data.frfDatumId}</td>
        <td className="text-center">{data.createdAt}</td>
        <td className="text-center">{data.email}</td>
        <td className="text-center">
          <button
            className="btn btn-danger btn-sm"
            style={{ width: "7em" }}
            onClick={(event) => {
              event.stopPropagation();
              setShowDeleteDialog(true);
              setFrfDatumIdToDelete(data.frfDatumId);
            }}
          >
            Delete
            <i className="ion ion-md-trash ml-1" />
          </button>
        </td>
      </tr>
    ));
  };

  const renderEventsTable = () => {
    return eventLogs.map((data) => {
      const eventDate = formatDate(data.eventDate);
      const createdAt = formatDate(data.createdAt);

      return (
        <tr key={data.eventId} style={{ height: "10px" }}>
          <td className="text-center">{data.referenceNo}</td>
          <td className="text-center">{eventDate}</td>
          <td className="text-center">{createdAt}</td>
          <td className="text-center">{data.eventType}</td>
          <td className="d-flex justify-content-around text-center">
            {data.status}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="spindle-health-monitor-container mb-5">
      <div className="row main-container-class">
        <div className="d-flex flex-row">
          <div style={{ marginTop: "-5rem" }}>
            <button
              className="btn btn-primary waves-effect button back-to-shop-floor"
              onClick={() => history.goBack()}
            >
              Back to Shop Floor
            </button>
            <div className="ml-5 d-flex justify-content-around">
              <div>
                <h1 className="text-center">Spindle Health Monitoring</h1>
                <h3 className="text-center">
                  {!machineByMachineIdLoading && !machineByMachineIdError
                    ? `Machine Name: ${machineDetails?.machineName}`
                    : null}
                </h3>
              </div>
            </div>
            <LineChart
              width={800}
              height={500}
              data={parseData(frfData.frfXxData, frfData.frfYyData)}
              margin={{
                // top: 10,
                // right: 30,
                left: 45,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="6 6" />
              <XAxis
                dataKey="frequency"
                height={50}
                label={{
                  value: "Frequency (Hz)",
                  position: "right",
                  dx: -110,
                  dy: 8,
                }}
              />
              <YAxis
                width={60}
                label={{
                  value: "Magnitude (m/N)",
                  position: "insideTopLeft",
                  angle: -90,
                  dx: -35,
                  dy: 120,
                }}
              />
              <Tooltip />
              <Legend />
              {activeMagnitudes.includes("magnitudeX") && (
                <Line
                  type="monotone"
                  dataKey="magnitudeX"
                  stroke="#8884d8"
                  dot={false}
                  activeDot={false}
                />
              )}
              {activeMagnitudes.includes("magnitudeY") && (
                <Line
                  type="monotone"
                  dataKey="magnitudeY"
                  stroke="#82ca9d"
                  dot={false}
                  activeDot={false}
                />
              )}
            </LineChart>
            <div className="d-flex flex-row justify-content-center align-items-center mt-2 ml-3">
              {chartButtons.map((button, index) => (
                <button
                  key={index}
                  className={button.className}
                  onClick={button.onClick}
                >
                  {button.label}
                </button>
              ))}
            </div>
            <div
              className="table-responsive mb-5 ml-5"
              style={{ marginTop: "-6rem" }}
            >
              <div className="d-flex justify-content-end mb-2">
                <div></div>
                <div>
                  <button
                    className="btn btn-primary waves-effect button"
                    onClick={() => setActiveComponent("ReportAnEvent")}
                  >
                    Report an Event
                  </button>
                </div>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">Reference No</th>
                    <th className="text-center">Submission Date</th>
                    <th className="text-center">Event Date</th>
                    <th className="text-center">Event Type</th>
                    <th className="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {eventLogsByMachineIdError ? (
                    <tr>
                      <td colSpan="3">Error Loading Event data</td>
                    </tr>
                  ) : eventLogsByMachineIdLoading ? (
                    <tr>
                      <td colSpan="3">Loading Event Data...</td>
                    </tr>
                  ) : (
                    renderEventsTable(eventLogs)
                  )}
                </tbody>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="datatable_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={
                        eventTableOffset === 0
                          ? "paginate_button page-item previous disabled"
                          : null
                      }
                      id="datatable_previous"
                    >
                      <button
                        type="button"
                        aria-controls="datatable"
                        className="page-link"
                        disabled={eventTableOffset === 0 ? true : false}
                        onClick={() =>
                          setEventTableOffset(eventTableOffset - 6)
                        }
                      >
                        Prev Page
                      </button>
                    </li>
                    <li
                      className={
                        eventLogs?.length < 6
                          ? "paginate_button page-item previous disabled"
                          : "none"
                      }
                      id="datatable_next"
                    >
                      <button
                        type="button"
                        aria-controls="datatable"
                        className="page-link"
                        disabled={eventLogs?.length < 6 ? true : false}
                        onClick={() =>
                          setEventTableOffset(eventTableOffset + 6)
                        }
                      >
                        Next Page
                      </button>
                    </li>
                  </ul>
                </div>
              </table>
            </div>
          </div>
          <div className="table-responsive ml-3">
            <div className="d-flex justify-content-end mb-2">
              <div></div>
              <div>
                <button
                  className="btn btn-primary waves-effect button"
                  style={{ width: "auto" }}
                  onClick={() => setActiveComponent("UploadFrfData")}
                >
                  Upload New Data
                </button>
              </div>
            </div>
            <table
              id="datatable"
              className="table table-bordered text-center"
              role="grid"
              aria-describedby="datatable_info"
            >
              <thead>
                <tr>
                  <th className="text-center">Frf Datum Id</th>
                  <th className="text-center">Upload Date</th>
                  <th>Created By</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {maintenanceFrfDataByMachineIdError ? (
                  <tr>
                    <td colSpan="3">Error Loading FRF data</td>
                  </tr>
                ) : maintenanceFrfDataByMachineIdLoading ? (
                  <tr>
                    <td colSpan="3">Loading FRF Data...</td>
                  </tr>
                ) : (
                  renderTableRows(
                    maintenanceFrfDataByMachineId,
                    handleDeleteFrfDataByFrfDatumId
                  )
                )}
              </tbody>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="datatable_paginate"
              >
                <ul className="pagination">
                  <li
                    className={
                      frfTableOffset === 0
                        ? "paginate_button page-item previous disabled"
                        : null
                    }
                    id="datatable_previous"
                  >
                    <button
                      type="button"
                      aria-controls="datatable"
                      className="page-link"
                      disabled={frfTableOffset === 0 ? true : false}
                      onClick={() => setFrfTableOffset(frfTableOffset - 6)}
                    >
                      Prev Page
                    </button>
                  </li>
                  <li
                    className={
                      maintenanceFrfDataByMachineId?.length < 6
                        ? "paginate_button page-item previous disabled"
                        : "none"
                    }
                    id="datatable_next"
                  >
                    <button
                      type="button"
                      aria-controls="datatable"
                      className="page-link"
                      disabled={
                        maintenanceFrfDataByMachineId?.length < 6 ? true : false
                      }
                      onClick={() => setFrfTableOffset(frfTableOffset + 6)}
                    >
                      Next Page
                    </button>
                  </li>
                </ul>
              </div>
            </table>
          </div>
        </div>
      </div>
      {componentMapping[activeComponent]}
      {showDeleteDialog && (
        <DeleteFrfRecordById
          handleDeleteFrfDataByFrfDatumId={handleDeleteFrfDataByFrfDatumId}
          setShowDeleteDialog={setShowDeleteDialog}
          frfDatumIdToDelete={frfDatumIdToDelete}
          selectedRow={selectedRow}
        />
      )}
    </div>
  );
};

export default SpindleHealthMonitoring;
